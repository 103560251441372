import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from "@mui/material"
import { DeleteBudgetAsync, GetBudgetsBudgetProps } from "../../../api";
import { useMutation } from "react-query";
import useFeedbackMessages from "../../../../core/hooks/useFeedbackMessages";
import { Formik } from "formik";
import { DialogProps } from "../../../../core/interfaces";

interface DeleteBudgetDialogProps extends DialogProps {
    budget? : GetBudgetsBudgetProps
}

const DeleteBudgetDialog: React.FC<DeleteBudgetDialogProps> = ({ budget, open, onCancel, afterSubmit}) => {

    const deleteBudgetMutation = useMutation((id: string) => deleteBudget(id));

    const { showMessages, showErrors } = useFeedbackMessages();

    const deleteBudget = async (id: string) => {
        const response = await DeleteBudgetAsync(id);
        if (response.isSuccess) {
            showMessages(['Orçamento excluído com sucesso!'])
            afterSubmit();
        }
        else {
            showErrors(response.errorMessages);
        }

    }


    return (

        <Formik
            initialValues={{}}
            onSubmit={() => deleteBudgetMutation.mutate(budget!.id)}
        >
            {({ submitForm }) => (
                <Dialog open={open}>
                    <DialogTitle>Remover Orçamento</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Deseja remover o orçamento {budget && budget.monthName}?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()}>Cancelar</Button>
                        <Button onClick={() => submitForm()}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

export default DeleteBudgetDialog
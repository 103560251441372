import { Typography, IconButton, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import GridContainer from '../../components/GridContainer'
import GridItem from '../../components/GridItem'
import {
    ArrowBack as ArrowBackIcon,
    Edit as EditIcon
} from '@mui/icons-material'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { BudgetDayExpenseProps, BudgetDayProps, BudgetProps, GetBudgetAsync, SuggestProps, UpdateBudgetAsync } from '../api'
import SuggestsContainer from '../components/SuggestsContainer'
import ButtonInput from '../../components/ButtonInput'
import useFeedbackMessages from '../../core/hooks/useFeedbackMessages'
import BudgetDaysContainer from '../components/BudgetDaysContainer'
import RemainingBudgetContainer from '../components/RemainingBudgetContainer'
import UpdateBudgetValueDialog, { UpdateBudgetValueProps } from '../components/Dialogs/UpdateBudgetValueDialog'
import _ from 'lodash'


const emptyBudget: BudgetProps = {
    id: "",
    month: 1,
    monthName: '',
    value: 0,
    suggests: [],
    budgetDays: [],
}

function UpdateBudgetView() {

    const navigate = useNavigate();

    const { budgetId } = useParams();

    const [budget, setBudget] = useState<BudgetProps>(emptyBudget);

    const [isUpdateBudgetValueOpen, setIsUpdateBudgetValueOpen] = useState<boolean>(false);

    const [showOnlyNoValueBudgetDays, setShowOnlyNoValueBudgetDays] = useState<boolean>(false);

    const getBudgetQueryResult = useQuery(['budget'],
        async () => GetBudgetAsync(budgetId || "0"), {
        initialData: emptyBudget,
        enabled: false,
        onSuccess: (data) => setBudget(data)
    })

    useEffect(() => {
        getBudgetQueryResult.refetch();
    }, [])


    const updateBudgetMutation = useMutation((data: BudgetProps) => updateBudget(data));

    const { showMessages, showErrors } = useFeedbackMessages();

    const updateBudget = async (data: BudgetProps) => {
        const response = await UpdateBudgetAsync(budget);
        if (response.isSuccess) {
            showMessages(['Orçamento atualizado com sucesso!'])
        }
        else {
            showErrors(response.errorMessages);
        }

    }

    const onAddBudgetDay = (budgetDay: BudgetDayProps) => {

        setBudget({
            ...budget,
            budgetDays: [
                ...budget.budgetDays,
                budgetDay
            ]
        })
    }

    const onUpdateBudgetDay = (budgetDay: BudgetDayProps) => {

        const budgetDayIndex = budget.budgetDays.findIndex(x => x.id == budgetDay.id);

        const budgetDays = budget.budgetDays;

        budgetDays[budgetDayIndex].name = budgetDay.name;
        budgetDays[budgetDayIndex].date = budgetDay.date;

        setBudget({
            ...budget,
            budgetDays: budgetDays
        })
    }

    const onRemoveBudgetDay = (budgetDayId: string) => {
        const newBudgetDays = budget.budgetDays.filter(s => s.id != budgetDayId);

        setBudget({
            ...budget,
            budgetDays: newBudgetDays
        })
    }

    const onAddSuggest = (suggest: SuggestProps) => {
        setBudget({
            ...budget,
            suggests: [
                ...budget.suggests,
                suggest
            ]
        })
    }

    const onRemoveSuggest = (suggestId: string) => {
        const newSuggests = budget.suggests.filter(s => s.id != suggestId);

        setBudget({
            ...budget,
            suggests: newSuggests
        })
    }

    const onSuggestCopy = (budgetDayId: string, suggestId: string) => {
        const newBudgetDays = budget.budgetDays;
        const suggest = budget.suggests.find(s => s.id == suggestId);

        const budgetDayIndex = budget.budgetDays.findIndex(bd => bd.id == budgetDayId);

        newBudgetDays[budgetDayIndex].name = suggest!.name;

        setBudget({
            ...budget,
            budgetDays: newBudgetDays
        })

    }

    const onAddBudgetDayExpense = (budgetDayId: string, budgetDayExpense: BudgetDayExpenseProps) => {

        const budgetDayIndex = budget.budgetDays.findIndex(b => b.id == budgetDayId);
        const budgetDayExpenses = [...budget.budgetDays[budgetDayIndex].expenses, budgetDayExpense];

        const budgetDays = budget.budgetDays;
        budgetDays[budgetDayIndex].expenses = budgetDayExpenses;
        budgetDays[budgetDayIndex].value = _.sumBy(budgetDayExpenses, 'value')

        setBudget({
            ...budget,
            budgetDays: budgetDays
        })
    }

    const onRemoveBudgetDayExpense = (budgetDayId: string, budgetDayExpenseId: string) => {
        const budgetDayIndex = budget.budgetDays.findIndex(b => b.id == budgetDayId);
        const budgetDayExpenses = budget.budgetDays[budgetDayIndex].expenses.filter(e => e.id != budgetDayExpenseId);

        const budgetDays = budget.budgetDays;
        budgetDays[budgetDayIndex].expenses = budgetDayExpenses;
        budgetDays[budgetDayIndex].value = _.sumBy(budgetDayExpenses, 'value')

        setBudget({
            ...budget,
            budgetDays: budgetDays
        })
    }

    const internalUpdateBudgetValue = (updatedBudget: UpdateBudgetValueProps) => {
        setBudget({
            ...budget,
            value: updatedBudget.value
        });
        setIsUpdateBudgetValueOpen(false);
    }

    return (
        <>
            <UpdateBudgetValueDialog open={isUpdateBudgetValueOpen} onCancel={() => setIsUpdateBudgetValueOpen(false)} budget={budget} onBudgetValueUpdated={internalUpdateBudgetValue} afterSubmit={() => { }} />
            <GridContainer display='flex' flexDirection='column'>
                <GridItem mx='20px' marginTop='20px' display='flex' alignItems='center' justifyContent='flex-start' gap='5px'>
                    <IconButton size='small' onClick={() => navigate(-1)}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant='h5'>{budget.monthName} - R${budget.value}</Typography>
                    <IconButton size='small' onClick={() => setIsUpdateBudgetValueOpen(true)}>
                        <EditIcon color='warning' />
                    </IconButton>
                </GridItem>
                <GridItem mx='40px' mt='40px' >
                    <RemainingBudgetContainer budget={budget} />
                </GridItem>
                <GridItem mx='40px' mt='40px' display='flex' flexDirection='row' gap='5px'>
                    <Typography variant='h6'>Apenas não preenchidos:</Typography>
                    <Switch checked={showOnlyNoValueBudgetDays} onClick={() => setShowOnlyNoValueBudgetDays(!showOnlyNoValueBudgetDays)} />
                </GridItem>
                <GridItem mx='20px' mt='20px' >
                    <SuggestsContainer onAddSuggest={onAddSuggest} onRemoveSuggest={onRemoveSuggest} suggests={budget.suggests} />
                    <BudgetDaysContainer showOnlyNoValueBudgetDays={showOnlyNoValueBudgetDays} onAddBudgetDayExpense={onAddBudgetDayExpense} onUpdateBudgetDay={onUpdateBudgetDay} onRemoveBudgetDayExpense={onRemoveBudgetDayExpense} budgetDays={budget.budgetDays} suggests={budget.suggests} onAddBudgetDay={onAddBudgetDay} onRemoveBudgetDay={onRemoveBudgetDay} onSuggestCopy={onSuggestCopy} />
                </GridItem>
                <GridItem mt='20px' mx='10%' mb='20px'>
                    <GridContainer display='flex' direction='row' alignItems='center' justifyContent='space-between'>
                        <ButtonInput buttonProps={{ color: 'error', sx: { width: '40%' }, onClick: () => { navigate(-1) } }}>Cancelar</ButtonInput>
                        <ButtonInput buttonProps={{
                            size: 'large', sx: { width: '40%' }, onClick: () => {
                                updateBudgetMutation.mutate(budget)
                            }
                        }}>Salvar</ButtonInput>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default UpdateBudgetView;
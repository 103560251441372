import { BottomNavigation, BottomNavigationAction, Box, Paper } from "@mui/material"
import GridContainer from "../../components/GridContainer"
import {
  Paid as PaidIcon,
  Sync as SyncIcon,
  CalendarMonth as CalendarMonthIcon
} from '@mui/icons-material'
import { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

function HomeView() {


  const [tabIndex, setTabIndex] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();

  const routes = {
    '/': 0,
    '/budgets': 0,
    '/monthly-planning': 1,
    '/sync-logs': 2,
  }
  useEffect(() => {
    setTabIndex(routes[location.pathname as keyof typeof routes])
  }, [location])

  const changeTab = (tabIndex: number, route: string) => {
    setTabIndex(tabIndex);
    navigate(route)
  }

  return (
    <Box height='100vh'>
      <GridContainer display='flex' flexDirection='column'>
        <Outlet />
      </GridContainer>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          value={tabIndex}
          onChange={(event: any, newValue: any) => {
            switch (newValue) {
              case 0:
                changeTab(0, '/budgets')
                break;
              case 1:
                changeTab(1, '/monthly-planning')
                break;
              case 2:
                changeTab(2, '/sync-logs')
                break;

            }
          }}
        >
          <BottomNavigationAction color='success' value={0} label="Orçamentos" icon={<PaidIcon color='success' />} />
          {/* <BottomNavigationAction color='success' value={1} label="Planejamento" icon={<CalendarMonthIcon color='success' />} />
          <BottomNavigationAction color='success' value={2} label="Sincronização" icon={<SyncIcon color='success' />} /> */}
        </BottomNavigation>
      </Paper>


    </Box>

  )
}

export default HomeView
import { Favorite as FavoriteIcon} from "@mui/icons-material";
import GridContainer from "../../../components/GridContainer";
import GridItem from "../../../components/GridItem";
import {Typography} from '@mui/material'

export default function Header() {
    return (
        <GridContainer>
            <GridItem display='flex' alignItems='center' justifyContent='center' gap='5px'>
                <Typography variant='h5'>Nosso Orçamento</Typography>
                <FavoriteIcon color='error'/>
            </GridItem>
        </GridContainer>
    )
}

import { createTheme, CssBaseline } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import routes from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { useState, useEffect } from "react";
import { getToken, getDecodedToken } from "./core/services";
import { UserContext } from "./core/contexts";
import FetchingBackdrop from "./components/FetchingBackdrop";
import AppSnackbar from "./components/Snackbar";
import FeedbackSnackbarProvider from "./core/providers/FeedbackSnackbarProvider";
import { green } from "@mui/material/colors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/pt-br';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchInterval: -1
        }
    }
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: green[400]
        }
    },

});

const router = createBrowserRouter([
    ...routes
])

interface AppUser {
    username: string,
    token?: string,
}


function App() {
    const [user, setUser] = useState<AppUser>({ username: '' })

    useEffect(() => {
        async function UpdateAppUser() {
            const token = getToken();
            if (token) {
                const data = getDecodedToken(token);
                setUser({ username: data.unique_name })
            }
        }
        UpdateAppUser();
    }, [])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
            <ThemeProvider theme={darkTheme}>
                <UserContext.Provider value={{ user, setUser }}>
                    <QueryClientProvider client={queryClient}>
                        <FeedbackSnackbarProvider>
                            <CssBaseline />
                            <FetchingBackdrop />
                            <RouterProvider router={router} />
                            <AppSnackbar />
                        </FeedbackSnackbarProvider>
                    </QueryClientProvider>
                </UserContext.Provider>
            </ThemeProvider>
        </LocalizationProvider>

    )
}

export default App
import { Accordion, AccordionDetails, AccordionSummary, Fab, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from "@mui/material"
import GridContainer from "../../../components/GridContainer"
import GridItem from "../../../components/GridItem"
import { BudgetDayExpenseProps, BudgetDayProps, SuggestProps } from "../../api"
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { useState } from "react"
import _ from 'lodash';
import AddBudgetDayExpenseDialog from "../Dialogs/AddBudgetDayExpenseDialog"

export interface BudgetDayExpensesContainerProps {
    budgetDayExpenses: BudgetDayExpenseProps[],
    onAddExpense: (expense: BudgetDayExpenseProps) => void;
    onRemoveExpense: (expenseId: string) => void;
}
const BudgetDayExpensesContainer: React.FC<BudgetDayExpensesContainerProps> = ({ budgetDayExpenses, onAddExpense, onRemoveExpense }) => {

    const [isAddBudgetDayExpenseOpen, setIsAddBudgetDayExpenseOpen] = useState<boolean>(false);


    const internalAddBudgetDayExpense = (budgetDayExpense: BudgetDayExpenseProps) => {
        onAddExpense(budgetDayExpense);
        setIsAddBudgetDayExpenseOpen(false);
    }

    const renderBudgetDayExpenses = () => {
        const orderedBudgetDayExpenses = _.orderBy(budgetDayExpenses, ['value'], ['desc']);

        return orderedBudgetDayExpenses.map((budgetDayExpense: BudgetDayExpenseProps, i: number) => (
            <Paper key={i} elevation={3}>
                <ListItem
                    
                    disablePadding
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => onRemoveExpense(budgetDayExpense.id)}>
                            <DeleteIcon color='error' />
                        </IconButton>
                    }>
                    <ListItemButton disableTouchRipple>
                        <ListItemText>{budgetDayExpense.name} - R${budgetDayExpense.value}</ListItemText>
                    </ListItemButton>
                </ListItem>
            </Paper>
        ))
    }

    return (
        <>
            <AddBudgetDayExpenseDialog open={isAddBudgetDayExpenseOpen} onCancel={() => setIsAddBudgetDayExpenseOpen(false)} onAddBudgetDayExpense={internalAddBudgetDayExpense} afterSubmit={() => { }} />
            <GridContainer display='flex' flexDirection='column'>
                <GridItem mx='20px' marginTop='20px' display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h6'>Despesas</Typography>
                    <Fab size='small' color='success' onClick={() => setIsAddBudgetDayExpenseOpen(true)} >
                        <AddIcon />
                    </Fab>
                </GridItem>
                <GridItem mx='20px' mt='30px'>
                    <List dense>
                        {renderBudgetDayExpenses()}
                    </List>

                </GridItem>
            </GridContainer>
        </>
    )
}

export default BudgetDayExpensesContainer
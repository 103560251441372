import jwt_decode from 'jwt-decode'

const SaveToken = (token : string) => {
    localStorage.setItem('token',token);
}

const getToken = () : string | null => {
  return localStorage.getItem('token');
}

const getDecodedToken = (token: string) : any => {
    if(token){
        return jwt_decode(token);
    }
    return null;
}

const clearToken = () =>{
    localStorage.removeItem('token');
}

const isTokenValid = () : boolean => {
    const token = getToken();

    if(token){
        const decodedToken : any = jwt_decode(token);
        const currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            return false;
        } else {
            return true;
        }
    }

    return false;
}

export {SaveToken, isTokenValid, getDecodedToken, getToken, clearToken}
import { Button, ButtonProps } from "@mui/material"

interface ButtonInputProps {
  buttonProps?: ButtonProps,
  children: React.ReactNode
}
const ButtonInput : React.FC<ButtonInputProps> = ({ buttonProps, children}) => {
  return (
    <Button variant="contained" size='large' fullWidth color='success'  {...buttonProps} > {children}</Button>
  )
}

export default ButtonInput
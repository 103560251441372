import React from 'react'
import { Grid, GridProps } from '@mui/material'

const GridContainer: React.FC<GridProps> = (props) => {
  return (
    <Grid container
      {...props}>
    </Grid>
  )
}

export default GridContainer;     
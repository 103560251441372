import { FormControl, FormHelperText, InputLabel, Select, SelectProps } from '@mui/material'
import { FieldHookConfig, useField } from 'formik'
import React from 'react'

interface SelectInputProps<T> {
    label: String,
    options: T[]
    fieldProps: FieldHookConfig<any>
    selectFieldProps?: SelectProps,
    renderValue: (data: T, index: number) => React.ReactNode;
}

const SelectInput = <T extends object>(props: SelectInputProps<T>) => {
    const [field, meta] = useField(props.fieldProps);
    return (
        <FormControl fullWidth>
            <InputLabel>{props.label}</InputLabel>
            <Select
                id={field.name}
                name={field.name}
                value={field.value}
                label="Age"
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={meta.touched && Boolean(meta.error)}
                {...props.selectFieldProps}
            >
                {props.options.map((option: T, index: number) => {
                    return (
                        props.renderValue(option, index)
                    )
                })}
            </Select>
            <FormHelperText>{Boolean(meta.error) ? meta.error : ""}</FormHelperText>
        </FormControl>
    )
}


export default SelectInput;
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { UpdateBudgetValueSchema } from "../../../schemas";
import { Formik } from "formik";
import GridContainer from "../../../../components/GridContainer";
import GridItem from "../../../../components/GridItem";
import TextInput from "../../../../components/TextInput";
import { DialogProps } from "../../../../core/interfaces";
import { BudgetProps } from "../../../api";

export interface UpdateBudgetValueDialogProps extends DialogProps {
    onBudgetValueUpdated: (updatedBudgetValue: UpdateBudgetValueProps) => void;
    budget: BudgetProps;
}

export interface UpdateBudgetValueProps{
    value: number;
}
const UpdateBudgetValueDialog: React.FC<UpdateBudgetValueDialogProps> = ({ open, onCancel, budget, onBudgetValueUpdated }) => {

    return (
        <Formik
            initialValues={{
                value: budget.value
            }}
            enableReinitialize
            validationSchema={UpdateBudgetValueSchema}
            onSubmit={(data: UpdateBudgetValueProps) => {
                onBudgetValueUpdated(data);
            }}
        >
            {({ submitForm, getFieldProps, resetForm }) => (
                <Dialog open={open}>
                    <DialogTitle>Atualizar valor do orçamento</DialogTitle>
                    <DialogContent>
                        <GridContainer mt='20px'>
                            <GridItem>
                                <TextInput
                                    textFieldProps={{ type: 'number' }}
                                    label='Novo valor'
                                    fieldProps={getFieldProps('value')}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()}>Cancelar</Button>
                        <Button onClick={() => {
                            submitForm().then(() => resetForm());
                        }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

export default UpdateBudgetValueDialog
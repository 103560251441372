import budgetRoutes from "./Budget/routes/routes";
import homeRoutes from "./Home/routes/routes";
import identityRoutes from "./Identity/routes/routes";
import {RouteObject} from 'react-router-dom'

const routes : RouteObject[] = [
    ...homeRoutes,
    ...identityRoutes,
    ...budgetRoutes
];

export default routes;
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material"
import { CopySuggestSchema } from "../../../schemas";
import { Formik } from "formik";
import GridContainer from "../../../../components/GridContainer";
import GridItem from "../../../../components/GridItem";
import { DialogProps } from "../../../../core/interfaces";
import { SuggestProps } from "../../../api";
import SelectInput from "../../../../components/SelectInput";

export interface CopySuggestDialogProps extends DialogProps {
    onSuggestCopy: (suggestId: string) => void;
    suggests: SuggestProps[]
}
const CopySuggestDialog: React.FC<CopySuggestDialogProps> = ({ suggests, open, onCancel, onSuggestCopy }) => {

    return (
        <Formik
            initialValues={{
                suggestId: ""
            }}
            enableReinitialize
            validationSchema={CopySuggestSchema}
            onSubmit={(data) => {
                onSuggestCopy(data.suggestId);
            }}
        >
            {({ submitForm, getFieldProps, resetForm, values }) => (
                <Dialog open={open}>
                    <DialogTitle>Selecionar Sugestão</DialogTitle>
                    <DialogContent>
                        <GridContainer mt='20px'>
                            <GridItem >
                                <SelectInput
                                    label='Sugestão'
                                    options={suggests}
                                    fieldProps={getFieldProps('suggestId')}
                                    renderValue={(suggest, index) => <MenuItem key={index} value={suggest.id}>{suggest.name} - {suggest.value}</MenuItem>}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()}>Cancelar</Button>
                        <Button onClick={() => {
                            submitForm().then(() => {
                                resetForm();
                            });
                        }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

export default CopySuggestDialog
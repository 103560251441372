import React from 'react'
import { Grid, GridProps } from '@mui/material'

const GridItem: React.FC<GridProps> = (props) => {
  return (
    <Grid
      xs={props.xs ?? 12}
      sm={props.sm ?? 12}
      md={props.md ?? 12}
      xl={props.xl ?? 12}
      item {...props}>
    </Grid>
  )
}

export default GridItem;
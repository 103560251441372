import { Alert, AlertTitle, Snackbar } from '@mui/material'
import React from 'react'
import useFeedbackMessages from '../../core/hooks/useFeedbackMessages'
import GridContainer from '../GridContainer';
import GridItem from '../GridItem';

const AppSnackbar: React.FC = () => {
    const { isError, messages, clearMessages } = useFeedbackMessages();

    const onClose = () => {
        clearMessages();
    }

    return (
        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} sx={{ width: '100%' }} transitionDuration={200} open={messages && messages.length > 0} autoHideDuration={6 * 1000} onClose={onClose}>
            <Alert onClose={onClose} severity={isError ? 'error' : 'success'} sx={{ width: '100%' }}>
                <GridContainer display='flex' flexDirection='column' gap='20px'>
                    {messages.map((message :string, i : number) => (
                        <GridItem key={i}>
                               {message}                 
                        </GridItem>
                    ))}
                   
                </GridContainer>
            </Alert>
        </Snackbar>

    )
}

export default AppSnackbar


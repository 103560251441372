import { ReactNode, useCallback, useState } from "react";
import { FeedbackMessagesContext, FeedbackMessagesContextProps } from "../contexts/FeedbackMessagesContext";
interface FeedbackSnackbarProviderProps {
    children: ReactNode
}


const FeedbackSnackbarProvider: React.FC<FeedbackSnackbarProviderProps> = ({ children }) => {
    const [messages, setMessages] = useState<string[]>([]);
    const [isError, setIsError] = useState<boolean>(false);

    const clearMessages = () => setMessages([]);

    const internalShowMessages = (isError : boolean, messages : string[]) => {
        setMessages(messages);
        setIsError(isError);
    };

    const contextValue : FeedbackMessagesContextProps = {
        isError: isError,
        messages: messages,
        showMessages: useCallback((messages : string[]) => internalShowMessages(false, messages), []),
        showErrors: useCallback((errors : string[]) => internalShowMessages(true, errors), []),
        clearMessages: useCallback(() => clearMessages(), [])
    };

    return (
        <FeedbackMessagesContext.Provider value={contextValue}>
            {children}
        </FeedbackMessagesContext.Provider>
    );
}

export default FeedbackSnackbarProvider;
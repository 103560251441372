import * as Yup from "yup";

export const AddSuggestSchema = Yup.object().shape({
    name: Yup.string()
        .required('Obrigatório'),
    value: Yup.number()
        .moreThan(0, "O valor deve ser maior que 0.")
        .required('Obrigatório'),
});

export const AddBudgetDaySchema = Yup.object().shape({
    name: Yup.string()
        .required('Obrigatório'),
    date: Yup.string()
        .required("Obrigatório")
});

export const UpdateBudgetDaySchema = Yup.object().shape({
    name: Yup.string()
        .required('Obrigatório'),
    date: Yup.string()
        .required("Obrigatório")
});


export const CopySuggestSchema = Yup.object().shape({
    suggestId: Yup.string()
        .required('Obrigatório')
});


export const AddBudgetDayExpenseSchema = Yup.object().shape({
    name: Yup.string()
        .required('Obrigatório'),
    value: Yup.number()
        .moreThan(0, "O valor deve ser maior que 0.")
        .required('Obrigatório'),
});

export const UpdateBudgetValueSchema = Yup.object().shape({
    value: Yup.number()
        .moreThan(0, "O valor deve ser maior que 0.")
        .required('Obrigatório'),
});

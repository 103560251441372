import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { UpdateBudgetDaySchema } from "../../../schemas";
import { Formik } from "formik";
import GridContainer from "../../../../components/GridContainer";
import GridItem from "../../../../components/GridItem";
import TextInput from "../../../../components/TextInput";
import { DialogProps } from "../../../../core/interfaces";
import { BudgetDayProps } from "../../../api";
import DatePickerInput from "../../../../components/DatePickerInput";

export interface UpdateBudgetDayDialogProps extends DialogProps {
    onUpdateBudgetDay: (budgetDay: BudgetDayProps) => void;
    budgetDay: BudgetDayProps
}
const UpdateBudgetDayDialog: React.FC<UpdateBudgetDayDialogProps> = ({ budgetDay, open, onCancel, onUpdateBudgetDay }) => {
    return (
        <Formik
            initialValues={budgetDay}
            enableReinitialize
            validationSchema={UpdateBudgetDaySchema}
            onSubmit={(data: BudgetDayProps) => {
                onUpdateBudgetDay(data);
            }}
        >
            {({ submitForm, getFieldProps }) => (
                <Dialog open={open}>
                    <DialogTitle>Atualizar {budgetDay.name}</DialogTitle>
                    <DialogContent>
                        <GridContainer mt='20px'>
                            <GridItem >
                                <TextInput
                                    label='Nome'
                                    fieldProps={getFieldProps('name')}
                                />
                            </GridItem>
                            <GridItem mt='10px'>

                                <DatePickerInput
                                    name='date'
                                    fieldProps={getFieldProps('date')}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()}>Cancelar</Button>
                        <Button onClick={() => {
                            submitForm();
                        }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

export default UpdateBudgetDayDialog
import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .required('Obrigatório'),
    password: Yup.string()
        .required('Obrigatório'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'As senhas devem ser iguais')
        .required('Obrigatório'),
});


export const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .required('Obrigatório'),
    password: Yup.string()
        .required('Obrigatório'),
});

import { FieldHookConfig, useField, useFormikContext } from 'formik'
import React from 'react'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment';
import dayjs from 'dayjs';

export interface DatePickerInputProps {
    name: string,
    fieldProps: FieldHookConfig<any>
}

const DatePickerInput: React.FC<DatePickerInputProps> = ({ name, fieldProps }) => {

    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(fieldProps);

    return (
        <>
            <MobileDatePicker
                value={dayjs(field.value)}
                onChange={(value, context) => { 
                    if(!context.validationError){
                        setFieldValue(name, moment.utc(value?.toDate()).local().format('YYYY-MM-DDTHH:mm'));
                    }
                    else{
                        setFieldValue(name, "")
                    }
                    
                 }}
                sx={{ width: '100%' }}
                label='Selecione o dia'
                slotProps={{
                    textField: {
                        helperText: Boolean(meta.error) ? meta.error : "" ,
                    },
                }}
                localeText={{
                    toolbarTitle: 'Selecione o dia'
                }}

            />
        </>
    )
}


export default DatePickerInput;
import { Backdrop, CircularProgress } from '@mui/material';
import { useIsFetching, useIsMutating } from 'react-query'

const FetchingBackdrop: React.FC = () => {
    const isFetching = useIsFetching() == 1;
    const isMutation = useIsMutating() == 1;
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
            open={isMutation || isFetching}
        >
            <CircularProgress color='success' />
        </Backdrop>
    );
}

export default FetchingBackdrop;
import { Accordion, AccordionDetails, AccordionSummary, Fab, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from "@mui/material"
import GridContainer from "../../../components/GridContainer"
import GridItem from "../../../components/GridItem"
import { BudgetDayExpenseProps, BudgetDayProps, SuggestProps } from "../../api"
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { useState } from "react"
import AddBudgetDayDialog from "../Dialogs/AddBudgetDayDialog"
import BudgetDayHeader from "../BudgetDayHeader"
import _ from 'lodash';
import BudgetDayExpensesContainer from "../BudgetDayExpensesContainer"

export interface BudgetDaysContainerProps {
    suggests: SuggestProps[],
    budgetDays: BudgetDayProps[],
    showOnlyNoValueBudgetDays: boolean;
    onAddBudgetDay: (budgetDay: BudgetDayProps) => void;
    onUpdateBudgetDay: (budgetDay: BudgetDayProps) => void;
    onRemoveBudgetDay: (budgetDayId: string) => void;
    onSuggestCopy: (budgetDayId: string, suggestId: string) => void;
    onAddBudgetDayExpense: (budgetDayId: string, budgetDayExpense: BudgetDayExpenseProps) => void;
    onRemoveBudgetDayExpense: (budgetDayId: string, budgetDayExpenseId: string) => void;
}
const BudgetDaysContainer: React.FC<BudgetDaysContainerProps> = ({ budgetDays, suggests, showOnlyNoValueBudgetDays, onSuggestCopy, onAddBudgetDay, onUpdateBudgetDay, onRemoveBudgetDay, onAddBudgetDayExpense, onRemoveBudgetDayExpense }) => {

    const [isAddBudgetDayOpen, setIsAddBudgetDayOpen] = useState<boolean>(false);

    const internalAddBudgetDay = (budgetDay: BudgetDayProps) => {
        onAddBudgetDay(budgetDay);
        setIsAddBudgetDayOpen(false);
    }


    const renderBudgetDays = () => {
        
        if(showOnlyNoValueBudgetDays){
            budgetDays = budgetDays.filter(bd => bd.value === 0);
        }
        
        const orderedBudgetDays = _.sortBy(budgetDays, function (dateObj) {
            return new Date(dateObj.date);
        });


        return orderedBudgetDays.map((budgetDay: BudgetDayProps, i: number) => (
            <Paper key={i} elevation={3} sx={{padding: '10px'}}>
                <GridContainer  mb='20px' display='flex' alignItems='flex-start' direction='column' justifyContent='center' width='100%' >
                <BudgetDayHeader suggests={suggests} onSuggestCopy={onSuggestCopy} onUpdateBudgetDay={onUpdateBudgetDay} onRemoveBudgetDay={onRemoveBudgetDay} budgetDay={budgetDay} />
                <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography>{budgetDay.name} - R${budgetDay.value}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BudgetDayExpensesContainer budgetDayExpenses={budgetDay.expenses}
                            onAddExpense={(budgetDayExpense) => onAddBudgetDayExpense(budgetDay.id, budgetDayExpense)}
                            onRemoveExpense={(budgetDayExpenseId) => onRemoveBudgetDayExpense(budgetDay.id, budgetDayExpenseId)} />
                    </AccordionDetails>
                </Accordion>
            </GridContainer>
            </Paper>
        ))
    }

    return (
        <>
            <AddBudgetDayDialog open={isAddBudgetDayOpen} onCancel={() => setIsAddBudgetDayOpen(false)} onAddBudgetDay={internalAddBudgetDay} afterSubmit={() => { }} />
            <GridContainer display='flex' flexDirection='column'>
                <GridItem mx='20px' marginTop='20px' display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h6'>Dias</Typography>
                    <Fab size='small' color='success' onClick={() => setIsAddBudgetDayOpen(true)} >
                        <AddIcon />
                    </Fab>
                </GridItem>
                <GridItem mx='20px' mt='30px' display='flex' flexDirection='column' gap='20px'>
                    {renderBudgetDays()}
                </GridItem>
            </GridContainer>
        </>
    )
}

export default BudgetDaysContainer
import React from 'react';

export interface FeedbackMessagesContextProps{
  isError: boolean,
  messages: string[],
  showErrors(errors: string[]): void,
  showMessages(messages: string[]): void,
  clearMessages: Function
}
export const FeedbackMessagesContext = React.createContext<FeedbackMessagesContextProps>({
  isError: false,
  messages: [],
  showErrors: (errors: string[]) => {},
  showMessages: (messages: string[]) => {},
  clearMessages: () => {}
});

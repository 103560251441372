import React, { ReactNode } from "react";
import { isTokenValid } from "../../../core/services";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
    children: ReactNode,
}

const PrivateRoute : React.FC<PrivateRouteProps> = (props) => {
    return(
        <>
            {(isTokenValid() ? props.children : <Navigate to='/login' /> )}
        </>
        
    )
}


export default PrivateRoute;
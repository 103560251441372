import {RouteObject} from 'react-router-dom'
import UpdateBudgetView from '../views/UpdateBudgetView';

const budgetRoutes : RouteObject[] = [
    {
        path: 'budgets/update/:budgetId',
        element: <UpdateBudgetView/>
    }
]

export default budgetRoutes;
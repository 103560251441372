import {RouteObject} from 'react-router-dom'
import HomeView from '../views/HomeView';
import PrivateRoute from '../../Identity/components/PrivateRoute';
import BudgetsView from '../views/BudgetsView';
import SyncLogsView from '../views/SyncLogsView';
import MonthlyPlanningView from '../views/MonthlyPlanningView';

const homeRoutes : RouteObject[] = [
    {
        path: '/',
        element: <PrivateRoute><HomeView/></PrivateRoute>,
        children: [
            {
                path: '/',
                element: <PrivateRoute><BudgetsView/></PrivateRoute>
            },
            {
                path: '/budgets',
                element: <PrivateRoute><BudgetsView/></PrivateRoute>
            }
        ]
    }
]

export default homeRoutes;
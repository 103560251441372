import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { AddSuggestSchema } from "../../../schemas";
import { Formik } from "formik";
import GridContainer from "../../../../components/GridContainer";
import GridItem from "../../../../components/GridItem";
import TextInput from "../../../../components/TextInput";
import { DialogProps } from "../../../../core/interfaces";
import {v4 as uuid} from 'uuid';
import { SuggestProps } from "../../../api";

export interface AddSuggestDialogProps extends DialogProps {
    onAddSuggest: (suggest: SuggestProps) => void;
}
const AddSuggestDialog: React.FC<AddSuggestDialogProps> = ({ open, onCancel, onAddSuggest }) => {

    return (
        <Formik
            initialValues={{
                id: uuid(),
                name: "",
                value: 0
            }}
            enableReinitialize
            validationSchema={AddSuggestSchema}
            onSubmit={(data: SuggestProps) => {
                onAddSuggest(data);
            }}
        >
            {({ submitForm, getFieldProps, resetForm }) => (
                <Dialog open={open}>
                    <DialogTitle>Adicionar Sugestão</DialogTitle>
                    <DialogContent>
                        <GridContainer mt='20px'>
                            <GridItem >
                                <TextInput
                                    label='Nome'
                                    fieldProps={getFieldProps('name')}
                                />
                            </GridItem>
                            <GridItem>
                                <TextInput
                                    textFieldProps={{ type: 'number' }}
                                    label='Valor'
                                    fieldProps={getFieldProps('value')}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()}>Cancelar</Button>
                        <Button onClick={() => {
                            submitForm().then(() => resetForm());
                        }}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

export default AddSuggestDialog
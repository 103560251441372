import { TextField, TextFieldProps } from '@mui/material'
import { FieldHookConfig, useField } from 'formik'
import React from 'react'

export interface TextInputProps {
    label: string,
    fieldProps: FieldHookConfig<any>
    textFieldProps?: TextFieldProps
}

const TextInput: React.FC<TextInputProps> = ({ label, fieldProps, textFieldProps }) => {
    const [field, meta] = useField(fieldProps);
    return (
        <TextField
            id={field.name}
            name={field.name}
            label={label}
            value={field.value}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={Boolean(meta.error)}
            helperText={Boolean(meta.error) ? meta.error : "" }
            color='success'
            variant="outlined"
            margin='normal'
            fullWidth
            {...textFieldProps}
        />
    )
}


export default TextInput;
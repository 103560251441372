import {Typography } from "@mui/material"
import GridContainer from "../../../components/GridContainer"
import { BudgetProps } from "../../api"
import {
    Check as CheckedIcon,
    Warning as WarningIcon,
    Error as ErrorIcon
} from '@mui/icons-material'
import _ from 'lodash';

export interface RemainingBudgetContainerProps {
    budget: BudgetProps
}
const RemainingBudgetContainer: React.FC<RemainingBudgetContainerProps> = ({ budget }) => {

    const budgetDaysValuesSum = _.sumBy(budget.budgetDays, (d) => d.value);

    const getRemainingValue = (): number => {
        const remaining = budget.value - budgetDaysValuesSum;

        return Number(_.round(remaining, 2).toFixed(2));
    }

    const renderRemainingWarning = () => {
        let icon = <CheckedIcon color='success' />

        const remainingPercentage = budgetDaysValuesSum / budget.value;

        if (remainingPercentage > 0.90) {
            icon = <ErrorIcon color='error' />
        }
        else if (remainingPercentage > 0.75) {
            icon = <WarningIcon color='warning' />
        }

        return icon;
    }

    return (
        <GridContainer display='flex' flexDirection='row' alignItems='center' gap='5px'>
            <Typography variant='h6'>Restante: R$</Typography>
            <Typography variant='h6'>{getRemainingValue()}</Typography>
            {renderRemainingWarning()}
        </GridContainer>
    )
}

export default RemainingBudgetContainer;
import { Fab, IconButton, ListItem, ListItemButton, ListItemText, Paper, Typography } from "@mui/material"
import GridContainer from "../../../components/GridContainer"
import GridItem from "../../../components/GridItem"
import { SuggestProps } from "../../api"
import {
    Add as AddIcon,
    Delete as DeleteIcon
} from '@mui/icons-material'
import { useState } from "react"
import AddSuggestDialog from "../Dialogs/AddSugestDialog"

export interface SuggestsContainerProps {
    suggests: SuggestProps[],
    onAddSuggest: (suggest: SuggestProps) => void;
    onRemoveSuggest: (suggestId: string) => void;
}
const SuggestsContainer: React.FC<SuggestsContainerProps> = ({ suggests, onAddSuggest, onRemoveSuggest }) => {

    const [isAddSuggestOpen, setIsAddSuggestOpen] = useState<boolean>(false);


    const internalAddSuggest = (suggest: SuggestProps) => {
        onAddSuggest(suggest);
        setIsAddSuggestOpen(false);
    }
    const renderSuggests = () => {
        return suggests.map((suggest: SuggestProps, i: number) => (
            <Paper elevation={3} key={i} sx={{ marginBottom: '2px' }}>
                <ListItem
                    disablePadding
                    secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => onRemoveSuggest(suggest.id)}>
                            <DeleteIcon color='error' />
                        </IconButton>
                    }>
                    <ListItemButton disableTouchRipple>
                        <ListItemText>{suggest.name} - R${suggest.value}</ListItemText>
                    </ListItemButton>
                </ListItem>
            </Paper>
        ))
    }

    return (
        <>
            <AddSuggestDialog open={isAddSuggestOpen} onCancel={() => setIsAddSuggestOpen(false)} onAddSuggest={internalAddSuggest} afterSubmit={() => { }} />
            <GridContainer display='flex' flexDirection='column'>
                <GridItem mx='20px' marginTop='20px' display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h6'>Sugestões</Typography>
                    <Fab size='small' color='success' onClick={() => setIsAddSuggestOpen(true)} >
                        <AddIcon />
                    </Fab>
                </GridItem>
                <GridItem mx='20px' mt='20px' sx={{ overflowY: 'auto' }} maxHeight='80vh'>
                    {renderSuggests()}
                </GridItem>
            </GridContainer>
        </>
    )
}

export default SuggestsContainer
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from "@mui/material"
import { AddBudgetAsync, AddBudgetProps } from "../../../api";
import { useMutation } from "react-query";
import useFeedbackMessages from "../../../../core/hooks/useFeedbackMessages";
import { AddBudgetSchema } from "../../../schemas";
import { Formik } from "formik";
import GridContainer from "../../../../components/GridContainer";
import GridItem from "../../../../components/GridItem";
import TextInput from "../../../../components/TextInput";
import SelectInput from "../../../../components/SelectInput";
import { months } from "../../../../core/services/MonthService";
import { DialogProps } from "../../../../core/interfaces";

const AddBudgetDialog: React.FC<DialogProps> = ({ open, onCancel, afterSubmit }) => {

    const addBudgetMutation = useMutation((data: AddBudgetProps) => addBudget(data));

    const { showMessages, showErrors } = useFeedbackMessages();

    const addBudget = async (data: AddBudgetProps) => {
        const response = await AddBudgetAsync(data);
        if (response.isSuccess) {
            showMessages(['Orçamento criado com sucesso!'])
            afterSubmit();
        }
        else {
            showErrors(response.errorMessages);
        }

    }


    return (

        <Formik
            initialValues={{
                month: 1,
                value: 0
            }}
            validationSchema={AddBudgetSchema}
            onSubmit={(data) => addBudgetMutation.mutate(data)}
        >
            {({ submitForm, getFieldProps }) => (
                <Dialog open={open}>
                    <DialogTitle>Adicionar Orçamento</DialogTitle>
                    <DialogContent>
                        <GridContainer mt='20px'>
                            <GridItem >
                                <SelectInput
                                    label='Mês'
                                    options={months}
                                    fieldProps={getFieldProps('month')}
                                    renderValue={(m, index) => <MenuItem key={index} value={m.month}>{m.name}</MenuItem>}

                                />
                            </GridItem>
                            <GridItem>
                                <TextInput
                                    textFieldProps={{type: 'number'}}
                                    label='Valor'
                                    fieldProps={getFieldProps('value')}
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCancel()}>Cancelar</Button>
                        <Button onClick={() => submitForm()}>Ok</Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    )
}

export default AddBudgetDialog
import { Typography, Fab, List, Paper, ListItem, IconButton, ListItemButton, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import GridContainer from '../../components/GridContainer'
import GridItem from '../../components/GridItem'
import {
    Delete as DeleteIcon,
    Add as AddIcon,
} from '@mui/icons-material'
import { useQuery } from 'react-query'
import { GetBudgetsAsync, GetBudgetsBudgetProps } from '../api'
import AddBudgetDialog from '../components/Dialogs/AddBudgetDialog'
import DeleteBudgetDialog from '../components/Dialogs/DeleteBudgetDialog'
import { useNavigate } from 'react-router-dom'



function BudgetsView() {
    const [isAddBudgetOpen, setIsAddBudgetOpen] = useState<boolean>(false);

    const [isDeleteBudgetOpen, setIsDeleteBudgetOpen] = useState<boolean>(false);
    const [toDeleteBudget, setToDeleteBudget] = useState<GetBudgetsBudgetProps | undefined>();

    const navigate = useNavigate();

    const getBudgetsQueryResult = useQuery(['myBudgets'],
        async () => GetBudgetsAsync(), {
        initialData: [],
        enabled: false
    })

    useEffect(() => {
        getBudgetsQueryResult.refetch();
    }, [])

    const renderBudgets = () => {
        return !getBudgetsQueryResult.isFetching && getBudgetsQueryResult.data!.map((budget: GetBudgetsBudgetProps, i: number) => (
            <Paper elevation={3} key={i} sx={{ marginBottom: '2px' }} >
                <ListItem

                    disablePadding
                    secondaryAction={
                        <IconButton onClick={() => {
                            setIsDeleteBudgetOpen(true);
                            setToDeleteBudget(budget);
                        }}>
                            <DeleteIcon color='error' />
                        </IconButton>
                    }>

                    <ListItemButton onClick={() => navigate('/budgets/update/' + budget.id)}>
                        <ListItemText>{budget.monthName} - R${budget.value}</ListItemText>
                    </ListItemButton>
                </ListItem>
            </Paper>
        ))
    }

    const onAddBudgetDialogSubmit = () => {
        setIsAddBudgetOpen(false);
        getBudgetsQueryResult.refetch();
    }

    const onDeleteBudgetDialogSubmit = () => {
        setIsDeleteBudgetOpen(false);
        getBudgetsQueryResult.refetch();
    }

    return (
        <>
            <DeleteBudgetDialog budget={toDeleteBudget} open={isDeleteBudgetOpen} onCancel={() => setIsDeleteBudgetOpen(false)} afterSubmit={onDeleteBudgetDialogSubmit} />
            <AddBudgetDialog open={isAddBudgetOpen} onCancel={() => setIsAddBudgetOpen(false)} afterSubmit={onAddBudgetDialogSubmit} />
            <GridContainer display='flex' flexDirection='column'>
                <GridItem mx='20px' marginTop='20px' display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography variant='h5'>Orçamentos</Typography>
                    <Fab color='success' size='medium' onClick={() => setIsAddBudgetOpen(true)}>
                        <AddIcon />
                    </Fab>
                </GridItem>
                <GridItem mx='20px' mt='20px' sx={{ overflowY: 'auto' }} maxHeight='80vh'>
                    <List>
                        {renderBudgets()}
                    </List>
                </GridItem>
            </GridContainer>
        </>
    )
}

export default BudgetsView
import LoginView from "../views/LoginView";
import {RouteObject} from 'react-router-dom'

const identityRoutes : RouteObject[] = [
    {
        path: '/login',
        element: <LoginView/>
    }
]

export default identityRoutes;
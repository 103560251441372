export const months = [
    {
        name: "Janeiro",
        month: 1,
    },
    {
        name: "Fevereiro",
        month: 2,
    },
    {
        name: "Março",
        month: 3,
    },
    {
        name: "Abril",
        month: 4,
    },
    {
        name: "Maio",
        month: 5,
    },
    {
        name: "Junho",
        month: 6,
    },
    {
        name: "Julho",
        month: 7,
    },
    {
        name: "Agosto",
        month: 8,
    },
    {
        name: "Setembro",
        month: 9,
    },
    {
        name: "Outubro",
        month: 10,
    },
    {
        name: "Novembro",
        month: 11,
    },
    {
        name: "Dezembro",
        month: 12,
    }];

export const getMonthName = (month: number): string => {
    return months[month].name;
}

export const getCurrentMonthName = (): string => {
    return months[new Date().getMonth()].name
}

export const getCurrentMonth = (): number => {
    return new Date().getMonth() + 1;
}

export const getPastMonthDate = (month: number): Date => {

    const currentDate = new Date();
 
    // Ensure month is within the valid range (0 to 11)
    month = (month - 1 + 12) % 12; // Decrement and wrap around to handle January

    const lastDayPreviousMonth = new Date(currentDate.getFullYear(), month + 1, 0);

    return lastDayPreviousMonth;
};

export const getPastMonth = (month: number): number => {
    const date = getPastMonthDate(month);
    return date.getMonth() + 1;
}


export const getYearFromPastMonth = (month: number): number => {

    if(month == 0){
        return new Date().getFullYear() - 1
    }

    const date = getPastMonthDate(month);
    return date.getFullYear();
}

export const getNextMonthDate = (month: number): Date => {
    const currentDate = new Date();

    // Ensure month is within the valid range (0 to 11)
    month = (month + 1) % 12; // Increment and wrap around to handle December

    const firstDayNextMonth = new Date(currentDate.getFullYear(), month, 1);

    return firstDayNextMonth;
};


export const getNextMonth = (month: number): number => {
    const date = getNextMonthDate(month);
    return date.getMonth() + 1;
}


export const getYearFromNextMonth = (month: number): number => {
    const date = getNextMonthDate(month);
    return date.getFullYear();
}


export const getCurrentYear = (): number => {
    return new Date().getFullYear();
}


import { ApiResponse } from "../../core/interfaces";
import { deleteAsync, getAsync, postAsync } from "../../core/services";

export interface GetBudgetsBudgetProps {
    id: string,
    month: number,
    monthName: string,
    value: number
}

export interface AddBudgetProps {
    month: number,
    value: number,
}

export interface GetSyncLogsLogProps {
    level: string,
    message: string,
    createdAt: string,
}


export interface GetMonthlyPlanningInputProps {
    monthNumber: number,
    year: number,
}
export interface GetMonthlyPlanningProps {
    monthNumber: number,
    monthName: string,
    year: number,
    categories: GetMonthlyPlanningCategoryProps[]
}

export interface GetMonthlyPlanningCategoryProps {
    name: string,
    planned: number,
    paid: number,
    paidExceedsPlanning: boolean,
    exceedValue: number
}


const AddBudgetAsync = async (data: AddBudgetProps) => {
    return await postAsync(`budgets`, data);
}

const GetBudgetsAsync = async (): Promise<GetBudgetsBudgetProps[]> => {
    var response = await getAsync(`budgets`);
    return response.data.budgets.map((budget: GetBudgetsBudgetProps) => budget);
}


const DeleteBudgetAsync = async (id: string) => {
    return await deleteAsync(`budgets/${id}`);
}


const GetSyncLogsAsync = async (): Promise<GetSyncLogsLogProps[]> => {
    var response = await getAsync(`users/logs`);
    return response.data.logs.map((log: GetSyncLogsLogProps) => log);
}

const GetMonthlyPlanningAsync = async (input : GetMonthlyPlanningInputProps): Promise<GetMonthlyPlanningProps> => {
    var response = await getAsync(`monthly-planning/${input.year}/${input.monthNumber}`);
    return response.data;
}

const TriggerMonthlyPlanningSyncAsync = async (): Promise<ApiResponse> => {
    var response = await postAsync(`monthly-planning/trigger`);
    return response;
}

export { GetBudgetsAsync, AddBudgetAsync, DeleteBudgetAsync, GetSyncLogsAsync, GetMonthlyPlanningAsync, TriggerMonthlyPlanningSyncAsync }
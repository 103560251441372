import { useContext } from 'react'
import GridContainer from '../../components/GridContainer'
import GridItem from '../../components/GridItem';
import Header from '../components/header';
import { Link, Typography } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Formik } from 'formik';
import { LoginSchema } from '../schemas';
import { useMutation } from 'react-query';
import TextInput from '../../components/TextInput';
import ButtonInput from '../../components/ButtonInput';
import { Auth } from '../api';
import { SaveToken } from '../../core/services';
import { UserContext } from '../../core/contexts';
import useFeedbackMessages from '../../core/hooks/useFeedbackMessages';

interface LoginProps {
    username: string,
    password: string
}
const Login = () => {

    const navigate = useNavigate();

    const loginMutation = useMutation((data: LoginProps) => handleAuth(data));

    const { setUser } = useContext(UserContext);

    const {showMessages, showErrors } = useFeedbackMessages();
    
    const handleAuth = async (data: LoginProps) => {
        const response = await Auth(data);
        if (response.isSuccess) {
            SaveToken(response.data);
            setUser({ username: data.username, token: response.data })
            showMessages([`Olá ${data.username}`])
            navigate('/')
            return;
        }
        showErrors(response.errorMessages);
    }

    return (
        <GridContainer marginTop='20vh'>
            <GridItem>
                <Header />
                <GridContainer marginTop='20px'>
                    <GridItem display='flex' alignItems='center' justifyContent='center'>
                        <Formik
                            initialValues={{
                                username: '',
                                password: ''
                            }}
                            validationSchema={LoginSchema}
                            onSubmit={(data) => loginMutation.mutate(data)}
                        >
                            {({ submitForm, getFieldProps }) => (
                                <GridContainer marginX='30px'>
                                    <GridItem >
                                        <TextInput
                                            label='Usuário'
                                            fieldProps={getFieldProps('username')}
                                        />
                                    </GridItem>
                                    <GridItem>
                                        <TextInput
                                            label='Senha'
                                            fieldProps={getFieldProps('password')}
                                            textFieldProps={{ type: 'password' }}
                                        />
                                    </GridItem>
                                    <GridItem marginTop='20px' display='flex' alignItems='center' justifyContent='center'>
                                        <ButtonInput buttonProps={{ onClick: submitForm }}>Entrar</ButtonInput>
                                    </GridItem>
                                    {/* <GridItem marginTop='20px' display='flex' flexDirection='row' alignItems='center' gap='5px' justifyContent='center'>
                                    <GridItem marginTop='20px' display='flex' flexDirection='row' alignItems='center' gap='5px' justifyContent='center'>
                                        <Typography variant='body1'>Não tem uma conta?</Typography>
                                        <Link underline='none' component={RouterLink} to='/signup' color='green'>
                                            <Typography variant='body1'>Cadastrar</Typography>
                                        </Link>
                                    </GridItem> */}
                                </GridContainer>
                            )}
                        </Formik>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>

    )
}

export default Login;
